import React, { useState, useEffect } from 'react';
import { eventList } from '@/definition/EVENT_LIST';
import { State } from '@/redux/state';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export const Ed125 = (): React.ReactElement => {
  const isAuth = useSelector((state: State) => state.isAuth);
  const [isLogin, setIsLogin] = useState(false);
  const [deadline, setDeadline] = useState(false);

  useEffect(() => {
    if (isAuth) {
      setIsLogin(true);
    } else {
      setIsLogin(false);
    }
  }, [isAuth]);

  useEffect(() => {
    const e = eventList.find(e => e.id === "0125");
    if (!e) return;
    const yyyymmdd = e.deadlineDate.split(' ')[0];
    const deadlineDate = new Date(`${yyyymmdd.replace(/-/g, "/")} 12:00:00`);
    const now = new Date;
    if (now > deadlineDate) setDeadline(true);
  }, []);

  const style = {
    img: 'border-gray-300 border-r border-b border-l'
  };
  return (
    <div className="event-contents">
      <div className="event-detail">
        <div className="md:-mx-16 sm:-mx-16">
          <img className={style.img} src="../images/Top/event/event_0125_mv.png" width="978" height="504" alt="" />
        </div>
        <h1 className="mt-56 sm:mt-30 text-29_46 sm:text-18_24 font-bold text-center">
          12/12（木）19:30  -<br />
          Webデザイナー向け<br />採用につながる<br className="md:hidden lg:hidden" />「自分の強み」を伝えるための<br />
          ポートフォリオとは
        </h1>
        {deadline &&
          <section className="mt-30 sm:mt-24 mb-40 sm:mb-18">
            <div className="flex flex-col items-center justify-center px-16 py-25 sm:p-16 bg-gray-100">
              <p className="mb-10 lg:mb-16 text-18_33 sm:text-14_21 font-bold">このセミナーの受付は終了しています。</p>
              <p className="text-16_28 sm:text-14_21">定期的にセミナーを開催しておりますので、<br className="sm:hidden" />最新の開催情報は<a className="textLink" href="../event/">こちら</a>よりご確認ください。</p>
            </div>
          </section>
        }
        <section>
          <section className="mt-80 sm:mt-55">
            <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">以下に該当する方にオススメです。</h2>
            <div className="mt-30 sm:mt-16 p-40 sm:p-24 box-line text-left">
              <ul className="text-16_28 sm:text-14_21">
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>自分が作成したポートフォリオについてプロのデザイナーからアドバイスが欲しい。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>自分以外のデザイナーのポートフォリオを見てみたい。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>ポートフォリオをブラッシュアップしたい。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>現状のポートフォリオの作り込みが魅力的になっているか知りたい。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>Web業界での転職に向けて、ポートフォリオの準備を進めたい。</span>
                </li>
                <li className="flex mb-4">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>ポートフォリオの添削や、希望企業への転職相談をしたい。</span>
                </li>
                <li className="flex">
                  <span><i className="mbx-naviInpage-item-icon mbx-icon mbx-icon-Done"></i></span>
                  <span>MATCHBOXの効果的な使い方を知りたい。</span>
                </li>
              </ul>
            </div>
          </section>
          {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/entry"
                >
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </Link>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">イベント内容</h2>
          <div className="mb-30">
            <img src="../images/Top/event/event_0094_image.jpg" width="980" height="374" alt="" />
          </div>
          <p className="text-16_28 sm:text-14_21">
            デザイナーがこれまでの経験や実績をまとめて、最終的に転職活動を行う上で必要不可欠なポートフォリオ。<br />
            「どんな情報をまとめて作れば良いのかわからない」、「一応作ってみたけど、書類選考が通らない」など悩まれている方が多くいらっしゃるのではないでしょうか。<br />
            今回のセミナーでは採用につながるポートフォリオとはどんなところが魅力的なのか、自分らしさが伝わるポートフォリオのポイントを抑えるためにはどんな思考プロセスが必要となるのかを講師のポートフォリオなどを用いてお伝えします。<br />
            <br />
            講師は未経験からWebデザイナーに転職し、ディレクション及びECコンサルタントとして活躍するWebディレクター兼Webデザイナーの大石慎平氏です。ポートフォリオをただの作品集ではなく、ご自身のこれまでの経歴やスキルを伝えるための書類とするために持つべき視点をお話いただきます。
          </p>
          <section className="mt-64 sm:mt-48">
            <h3 className="text-18_24 sm:text-14_21 font-bold">参加者の声</h3>
            <div className="mt-30 mb-15 sm:mt-16 sm:mb-8 p-40 sm:p-24 box-line text-left">
              <ul className="text-16_28 sm:text-14_21">
                <li className="flex mb-4"><span>・</span><span>ポートフォリオの改善点について客観的なアドバイスがもらえ、進め方がわかった。</span></li>
                <li className="flex"><span>・</span><span>誰に・何を・どうやってアピールするかを意識すると良いということがわかって良かった。</span></li>
                <li className="flex"><span>・</span><span>デザインした、だけでなくもっと細分化して載せた方がいいと教えて頂けたのも良かった。</span></li>
              </ul>
            </div>
            <p className="text-right text-16_28 sm:text-14_21">※参加者の声の一部を掲載しています</p>
          </section>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/entry"
                >
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </Link>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">講師プロフィール</h2>
          <div className="event-instructor box-line sm:p-16">
            <div className="instructor-photo">
              <img src="../images/Top/event/event_0010_prof.jpg" alt="" />
            </div>
            <dl className="instructor-prof sm:mt-16">
              <dt className="instructor-name text-16_28 font-bold">
                大石 慎平<span className="inline-block">（SHINPEI OISHI）</span>
              </dt>
              <dd className="instructor-position text-16_28 sm:text-14_21 mb-40 sm:mb-18">
                株式会社StoreHero グロースパートナー<br />
                フリーランスデザイナー<br />
                日本ディレクション協会 会長
              </dd>
              <dd className="instructor-career text-16_28 sm:text-14_21">
                大学在学時から独学でデザインを学び、未経験からデザインキャリアをスタート。2012年から制作会社、事業会社の双方でグラフィック、エディトリアル、Webデザインの制作業務に従事。2018年から復業でフリーランスデザイナーとしても活動を開始。プロジェクトを一貫して担当することが多く、ブランディング、UI/UX、ディレクションなど制作の幅広い領域で実績を積む。本職では、2021年9月、ECのビジネスグロースに特化した株式会社StoreHeroにジョイン。ECコンサルタントとしてデジタルマーケティング、プロジェクトマネジメントを主軸に国内外20以上のブランドを支援。課外活動として、日本ディレクション協会の運営として数多くのセミナー・イベントを実施。
              </dd>
            </dl>
          </div>
        </section>
        <section>
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 mb-40 sm:mb-18 sm:pb-6">当日スケジュール</h2>
          <table className="index timetable text-16_28 sm:text-14_21 mb-30 sm:mb-12">
            <tbody>
              <tr>
                <th scope="row" className="bg-gray-50 font-normal whitespace-nowrap" rowSpan={2}>
                  <span className="sm:block">19：30</span><span className="sm:block">～</span><span className="sm:block">21：00</span>
                </th>
                <td>
                  <ul className="text-16_28 sm:text-14_21">
                    <li className="flex"><span>・</span><span>自己紹介</span></li>
                    <li className="flex"><span>・</span><span>魅力的なポートフォリオとは</span></li>
                    <li className="flex"><span>・</span><span>企業が求めていることとは</span></li>
                    <li className="flex"><span>・</span><span>ポートフォリオの改善フローとポイント</span></li>
                    <li className="flex"><span>・</span><span>MATCHBOXでの改善事例紹介</span></li>
                    <li className="flex"><span>・</span><span>質疑応答</span></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/entry"
                >
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </Link>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section className="mb-64 sm:mb-48">
          <h2 className="text-24_24 sm:text-15_22 font-bold mt-80 sm:mt-55 sm:mb-8 sm:pb-6 text-left">開催概要</h2>
          <table className="summary text-16_28 sm:text-14_21">
            <tbody>
              <tr>
                <th scope="row" className="font-normal text-left">開催日時</th>
                <td>2024/12/12（木）19:30～<br /> [入室開始] 19:20～</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">開催方法</th>
                <td>Zoom開催<br />参加者の皆さまには別途URLをお送りします。
                </td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">対象者</th>
                <td>転職活動中、もしくは、転職準備中のWebデザイナーの方</td>
              </tr>
              <tr>
                <th scope="row" className="font-normal text-left">応募手順</th>
                <td>
                  <dl>
                    <dt className="mb-18 sm:mb-8">1.参加申し込み</dt>
                    <dd className="mb-38 sm:mb-16">MATCHBOXへログインいただき、お申し込みください。（アカウント作成は<a className="textLink" href="https://matchbox.work/entry">こちら</a>から）</dd>
                    <dt className="mb-18 sm:mb-8">2.ご連絡</dt>
                    <dd className="mb-38 sm:mb-16">マッチボックス セミナー事務局より、MATCHBOXに登録していただいているメールアドレス宛に当日の詳細のご連絡差し上げます。</dd>
                    <dt className="mb-18 sm:mb-8">3.開催当日</dt>
                    <dd>当日はZoomにて開催いたします。</dd>
                  </dl>
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        {!deadline && !isLogin &&
          <section className='mt-80 sm:mt-55 mb-40 sm:mb-18'>
            <div className='flex flex-col items-center justify-center'>
              <p className='mb-6 md:mb-12 lg:mb-12 text-16_28 sm:text-14_21'>MATCHBOXのアカウントをお持ちでない方</p>
              <p className='base-button mb-15 md:mb-24 lg:mb-24 text-16_28 sm:text-14_21'>
                <Link className='button size-m theme-secondary flex items-center flex-col font-bold' to="/entry"
                >
                  アカウントを新規で作成する
                  <span className='text-14_21 sm:text-12_20 text-center'>※セミナー参加にはMATCHBOXの事前登録が必要です。</span>
                </Link>
              </p>
              <p className='text-16_28 sm:text-14_21'>アカウントをお持ちの方は<Link className='textLink font-bold' to="/login">こちら</Link>からログイン後、お申込みください。</p>
            </div>
          </section>
        }
        <section className="mt-80 sm:mt-55">
          <h3 className="text-18_24 sm:text-14_21 font-bold">開催について</h3>
          <p className="mt-30 sm:mt-16 text-16_28 sm:text-14_21">
            Web・IT業界で必要不可欠な存在であるWebディレクター・Webデザイナーの皆さまを支援するために、日本ディレクション協会で受講者累計2,000名を超える「0からのWebディレクション講座」を生み出したメンバーでもある一般社団法人ディレクションサポート協会 会長の助田正樹氏と連携して、Webの現場最前線で働くロールモデルの方々をゲストとしてお呼びし、キャリアを考えるために有益な情報提供を開始しています。
          </p>
        </section>
        <section className="mt-80 sm:mt-55 p-40 sm:p-24 bg-gray-50 text-left">
          <h3 className="text-18_24 sm:text-14_21 font-bold">注意点</h3>
          <p className="mt-16 text-16_28 sm:text-14_21">
            ZoomURLはセミナー前日・または当日にメールにてお送りします。<br />
            マッチボックス登録時のアドレス、またはセミナー申込み時に入力したアドレスにURLへ届いていない場合は<a className='textLink' href='https://survey.mynavi.jp/cre/?enq=2va0sJC992s%3d' target={'_blank'} rel="noreferrer">こちら<i className="mbx-icon mbx-icon-External_link"></i></a>からお問い合わせください。
          </p>
        </section>
        <p className="text-center mt-120 sm:mt-55">
          <a className="textlink_back text-16_16 sm:text-14_14" href="./">
            <i className="mbx-icon mbx-icon-Arrow_Right"></i><span className="label pl-16">イベント一覧ページに戻る</span>
          </a>
        </p>
      </div>
    </div>
  );
};
